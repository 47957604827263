<template>
  <div
    v-show="hasTags"
    class="feed-tag-input"
  >
    <el-popover
      placement="bottom"
      trigger="click"
      popper-class="feed-tag-input-popover"
      @hide="onHide"
    >
      <div
        slot="reference"
        class="popover-trigger"
        @click="isOpen = !isOpen"
      >
        <el-input
          v-model="firstSelectedTag"
          :placeholder="label"
          :disabled="true"
        >
          <i
            slot="suffix"
            class="el-select__caret el-input__icon el-icon-arrow-up"
            :class="{ 'is-reverse' : isOpen }"
          ></i>
        </el-input>
        <el-badge
          v-if="hasManySelectedTags"
          :value="`+${manySelectedTagsCount}`"
          class="badge"
        ></el-badge>
      </div>
      <div
        v-loading="isLoading"
        class="popover-content"
      >
        <div class="tags-list">
          <ul>
            <li 
              v-for="(tag) in tags"
              :key="tag.id"
            >
              <el-checkbox
                :key="tag.id"
                v-model="selectedTags"
                :label="tag.id"
              >
                {{ tag.name }}
              </el-checkbox>
            </li>
          </ul>
        </div>
        <div class="buttons">
          <button
            class="fgh-button fgh-button--link"
            @click.prevent="doReset"
          >
            Reset
          </button>
          <button
            class="fgh-button fgh-button--primary"
            @click.prevent="doApply"
          >
            Apply
          </button>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    selectedTags: [],
    isOpen: false
  }),

  computed: {
    hasTags() {
      return this.tags.length > 0
    },
    hasManySelectedTags() {
      return this.selectedTags.length > 1
    },
    manySelectedTagsCount() {
      return this.selectedTags.length - 1
    },
    firstSelectedTag() {
      let tagName = ''

      if (this.selectedTags.length) {
        tagName = _.find(this.tags, ['id', this.selectedTags[0]]).name
        if (tagName.length > 12) {
          tagName =  tagName.substring(0,12) + '...'
        }
      }

      return tagName
    }
  },

  methods:{
    doApply() {
      this.$emit('input', this.selectedTags);
    },
    doReset() {
      this.selectedTags = [];

      this.$emit('input', this.selectedTags);
    },
    onHide() {
      if (this.isOpen) {
        this.isOpen = false
      }
    }
  },
}
</script>

<style lang="scss">
.feed-tag-input-popover {
  .el-loading-mask {
    background-color: rgba(255,255,255,.9);
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 5px;
    li {
      .el-checkbox {
        margin: 5px;
      }
    }
  }
  button {
    margin-top: 10px;
    padding: 10px 20px;
  }
}

.feed-tag-input {
  .el-checkbox {
    display: block;
  }

  .el-input {
    position: relative;
    .panel {
      position: absolute;
      top: -35px;
      left: 0px;
      min-width: 200px;
      height: auto;
      background: $white-color;
      border-radius: 3px;
      z-index: 10000;
    }
  }

  .el-input {
    color: $primary-color;
    cursor: pointer;

    @media (max-width: 416px) {
      display: block;
    }

    input {

      border: none;
      border-radius: 0;
      border-bottom: 1px solid $border-color;
      padding: 0 6px 6px 6px;
      height: auto;
      line-height: normal;
      font-size: 13px;
      font-weight: bold;
    }
    i {
      margin-left: 90px;
      color: $primary-color;
      font-weight: 900;
      @media (max-width: 416px) {
        margin-left: 0;
        float: right;
      }
    }
  }
}

.popover-trigger {
    position: relative;

    .el-input .el-select__caret {
        -webkit-transition: -webkit-transform .3s;
        transition: -webkit-transform .3s;
        transition: transform .3s;
        transition: transform .3s, -webkit-transform .3s;
        transition: transform .3s,-webkit-transform .3s;
        -webkit-transform: rotateZ(180deg);
        transform: rotateZ(180deg);

        &.is-reverse {
            -webkit-transform: rotateZ(0);
            transform: rotateZ(0);
        }
    }

    .el-input.is-disabled .el-input__inner, .el-input.is-disabled .el-input__icon {
        cursor: pointer;
    }

    .el-input.is-disabled .el-input__inner {
        background-color: transparent;
        color: $primary-color;
        border-color: $border-color;
    }

    .badge {
        position: absolute;
        top: 5px;
        right: 30px;
        @media (max-width: 767px) {
            top: 0px;
        }
        .el-badge__content {
          background-color: $status-red-color;
        }
    }
}
.popover-content {
    .tags-list {
        max-height: 300px;
        overflow-y: auto;
    }
    .buttons {
        border-top: 1px solid #E4E7ED;
        text-align: center;
    }
}
</style>
