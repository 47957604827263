<template>
  <div>
    <div class="search-sort-filter">
      <div class="container">
        <el-row>
          <el-col
            :xl="6"
            :lg="6"
            :md="6"
            :sm="24"
            class="left-side"
          >
            <div>
              <p>Filter</p>
            </div>
          </el-col>
          <el-col
            :xl="18"
            :lg="18"
            :md="18"
            :sm="24"
            class="right-side"
          >
            <div>
              <feed-tag-input
                v-if="tab.id !== 3 && tab.id !== 4 && tab.id !== 5"
                v-model="selectedTags"
                :tags="tab.tags"
                :is-loading="loading"
                label="Interests"
              />
              <span
                v-if="tab.id !== 1 && tab.id !== 4 && tab.id <= 5"
                class="txt"
              >Sort by</span>
              <div
                v-if="tab.id !== 1 && tab.id !== 4 && tab.id <= 5"
                class="drop-sel"
              >
                <el-select
                  v-model="localTab.sort"
                  v-loading="sortIsLoading"
                  placeholder="Select"
                  element-loading-spinner="el-icon-loading"
                  @change="search(); sortIsLoading = true"
                >
                  <el-option
                    v-for="item in tab.sortOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="search">
                <el-input
                  v-model="localTab.search"
                  v-loading="isSearchTyping"
                  placeholder="Search"
                  element-loading-spinner="el-icon-loading"
                  @input="isSearchTyping = true"
                >
                  <i
                    v-if="localTab.search !== ''"
                    slot="suffix"
                    class="el-input__icon el-icon-circle-close el-input__clear"
                    @click="clearSearch"
                  >
                  </i>
                </el-input>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div
      v-if="noResult"
      class="no-result"
    >
      <el-row>
        <el-col :xl="6">
          <vsvg-icon
            data="@assetsIcon/brand/feed-noresult-icon.svg"
            scale="1"
            original
          />
        </el-col>
        <el-col :xl="4">
          <h2>Sorry, no results found</h2>
        </el-col>
        <el-col :xl="6">
          <p>Check spelling, try using a different search term instead or something slightly less specific</p>
        </el-col>
        <el-col :xl="6">
          <button
            class="fgh-button fgh-button--link"
            @click.prevent="clearSearch"
          >
            Clear search
          </button>
        </el-col>
      </el-row>
    </div>
    <div
      v-loading="loading"
      class="container articles"
    >
      <el-row :gutter="30">
        <el-col
          v-for="(feed, i) in tab.items"
          :key="`feed-${i}`"
          :xl="6"
          :lg="8"
          :md="12"
          :sm="12"
          :xs="24"
          class="el-col-articles"
        >
          <router-link
            v-if="feed.feed_key === 'Article'"
            :to="{ name: 'feed-article', params: { id: feed.id, isActiveFilter: tab.id } }"
          >
            <article-card
              :media="feed.mediaFile.is_image? feed.mediaFile.large_thumb: feed.mediaFile.video_thumb"
              :is-image="feed.mediaFile.is_image"
              :title="feed.title"
              :author-avatar="feed.author_avatar_url"
              :author-name="feed.author"
              :date="feed.published_at"
              :featured="feed.is_featured"
            />
          </router-link>
          <router-link
            v-if="feed.feed_key === 'Event'"
            :to="{ name: 'feed-event', params: { id: feed.id, isActiveFilter: tab.id } }"
          >
            <event-card
              :title="feed.title"
              :featured="feed.is_featured"
              :venue="feed.city"
              :start-date="feed.start_date"
              :end-date="feed.end_date"
              :start-time="feed.start_time"
              :end-time="feed.end_time"
              :same-day="feed.same_day"
            />
          </router-link>
          <router-link
            v-if="feed.feed_key === 'Reward'"
            :to="{ name: 'feed-reward', params: { id: feed.id, isActiveFilter: tab.id } }"
            @click.capture.native="confirm($event, feed.unlocked)"
          >
            <offer-card
              :title="feed.title"
              :media="feed.mediaFile.is_image? feed.mediaFile.large_thumb: feed.mediaFile.video_thumb"
              :is-image="feed.mediaFile.is_image"
            />
          </router-link>
        </el-col>
      </el-row>
    </div>
    <transition name="fade">
      <div
        v-if="aLoading"
        v-loading="aLoading"
        class="container"
        style="min-height: 90px"
      ></div>
    </transition>
  </div>
</template>

<script>
import feedApi from '@/modules/feed/services/feed.api'
import ArticleCard from '@/modules/feed/components/article-card'
import EventCard from '@/modules/feed/components/event-card'
import OfferCard from '@/modules/feed/components/offer-card'
import feedTagInput from '@/modules/feed/components/feed-tag-input'
import _ from 'lodash'

export default {
  components: {
    ArticleCard,
    EventCard,
    OfferCard,
    feedTagInput,
  },

  props: {
    tab: {
      type: Object,
      default: () => {},
    },
    userTags: {
      type: Array,
      default: () => [],
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Number,
      default: 1,
    },
    staticTabCount: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      localTab: {},
      noResult: false,
      loading: true,
      aLoading: false,
      sortIsLoading: false,
      orderIsLoading: false,
      isSearchTyping: false,
      selectedTags: []
    }
  },

  watch: {
    bottom (bottom) {
      if (bottom) {
        this.localTab.page++

        if (this.localTab.page <= this.localTab.totalPages + 1) {
          this.aLoading = true
        }

        this.fetchItems()
      }
    },
    selectedTags (selectedTags) {
      this.localTab.items = []
      this.loading = true
      this.clearSearch()
    },
    'tab.search': _.debounce(function (newVal, oldVal) {
      this.isSearchTyping = false
      this.search()
    }, 1000)
  },

  created() {
    this.localTab = this.tab
    this.fetchItems()
  },

  methods: {
    fetchItems(type) {
      if (this.localTab.items.length === 0) {
        this.loading = true
      }

      this.localTab.order = this.localTab.sort === '' ? 'asc' : 'desc'

      if (this.isActive === 1) {
        this.fetchAll(type)
      }

      if (this.isActive === 2) {
        this.fetchArticles(type)
      }

      if (this.isActive === 3) {
        this.fetchEvents(type)
      }
      if (this.isActive === 4) {
        this.fetchRewards(type)
      }
      if (this.isActive === 5) {
        this.fetchPinned(type)
      }

      if (this.isActive > 5) {
        this.fetchCampaign(type)
      }
    },
    convertResponseToFeedItems(response, type) {
      this.noResult = false
      this.loading = false
      this.aLoading = false
      this.sortIsLoading = false
      this.orderIsLoading = false

      this.localTab.totalPages = response.data.meta.pagination.total_pages

      if (response.data.data.length === 0 && this.localTab.items.length === 0) {
        this.noResult = true
      } else if (type === 'search' || type === 'clear search') {
        this.localTab.items = response.data.data
        if (response.data.data.length === 0) {
          this.noResult = true
        }
      } else {
        this.localTab.items.push(...response.data.data);
      }
    },
    fetchAll(type) {
      feedApi
        .feed(false, {
          tags: JSON.stringify(this.selectedTags),
          per_page: this.tab.perPage,
          page: this.tab.page,
          search: this.tab.search,
          order_by: this.tab.sort,
          order: this.tab.order
        })
        .then((response)  => {
          this.convertResponseToFeedItems(response, type)
        })
    },
    fetchArticles(type) {
      feedApi
        .feed(false, {
          type: 'Modules\\Article\\Entities\\Article',
          tags: JSON.stringify(this.selectedTags),
          per_page: this.tab.perPage,
          page: this.tab.page,
          search: this.tab.search,
          order_by: this.tab.sort,
          order: this.tab.order
        }).then((response) => {
          this.convertResponseToFeedItems(response, type)
        })
    },
    fetchEvents(type) {
      feedApi
        .feed(false, {
          type: 'Modules\\Event\\Entities\\Event',
          tags: JSON.stringify(this.selectedTags),
          per_page: this.tab.perPage,
          page: this.tab.page,
          search: this.tab.search,
          order_by: this.tab.sort,
          order: this.tab.order
        }).then((response) => {
          this.convertResponseToFeedItems(response, type)
        })
    },
    fetchRewards(type) {
      feedApi
        .feed(false, {
          type: 'Modules\\Reward\\Entities\\Reward',
          per_page: this.tab.perPage,
          page: this.tab.page,
          search: this.tab.search,
          order_by: 'created_at',
          order: 'desc'
        }).then((response) => {
          this.convertResponseToFeedItems(response, type)
        })
    },
    fetchPinned(type) {
      feedApi
        .feed(false, {
          is_pinned: true,
          tags: JSON.stringify(this.selectedTags),
          per_page: this.tab.perPage,
          page: this.tab.page,
          search: this.tab.search,
          order_by: this.tab.sort,
          order: this.tab.order
        }).then((response) => {
          this.convertResponseToFeedItems(response, type)
        })
    },
    fetchCampaign(type) {
      let campaignId = this.tab.id - this.staticTabCount

      feedApi
        .feedCampaign(false, campaignId, {
          tags: JSON.stringify(this.selectedTags),
          per_page: this.tab.perPage,
          page: this.tab.page,
          search: this.tab.search,
        }).then((response) => {
          this.convertResponseToFeedItems(response, type)
        })
    },
    search() {
      this.localTab.page = 1
      this.fetchItems('search')
    },
    clearSearch() {
      this.localTab.search = ''
      this.noResult = false

      this.localTab.page = 1
      this.fetchItems('clear search')
    },
    confirm(e, unlocked) {
      if (!unlocked) {
        e.preventDefault()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
    .fade-enter-active {
        transition: ease-in opacity 2s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>
